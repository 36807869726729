import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/templates/default-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Privacy Policy`}</h1>
    <h2>{`Who we are`}</h2>
    <p>{`Fandomnesia is a Pop Culture or Entertainment World media site that provides interesting discussion content about comics, anime and manga, films, and drama series.`}</p>
    <p>{`Users are all people who access or enjoy content on this Fandomnesia site.`}</p>
    <p>{`User data collected by Fandomnesia is only as a reference/reference, Fandomnesia does not use the Data/Information of users for business or commercial purposes.`}</p>
    <p>{`Fandomnesia is not responsible for any errors and delays in updating data or information, or any losses arising from actions related to the use of the data/information presented.`}</p>
    <h2>{`Comments`}</h2>
    <p>{`When visitors leave comments on the site we collect the data shown in the comments form, and also the visitor’s IP address and browser user agent string to help spam detection.`}</p>
    <p>{`An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. The Gravatar service privacy policy is available here: `}<a parentName="p" {...{
        "href": "https://automattic.com/privacy/"
      }}>{`https://automattic.com/privacy/`}</a>{`. After approval of your comment, your profile picture is visible to the public in the context of your comment.`}</p>
    <h2>{`Media`}</h2>
    <p>{`If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website.`}</p>
    <h2>{`Cookies`}</h2>
    <p>{`If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.`}</p>
    <p>{`If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.`}</p>
    <p>{`When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.`}</p>
    <p>{`If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day.`}</p>
    <h2>{`Embedded content from other websites`}</h2>
    <p>{`Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.`}</p>
    <p>{`These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.`}</p>
    <h2>{`Who we share your data with`}</h2>
    <p>{`If you request a password reset, your IP address will be included in the reset email.`}</p>
    <h2>{`How long we retain your data`}</h2>
    <p>{`If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.`}</p>
    <p>{`For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.`}</p>
    <h2>{`What rights you have over your data`}</h2>
    <p>{`If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.`}</p>
    <h2>{`Where we send your data`}</h2>
    <p>{`Visitor comments may be checked through an automated spam detection service.`}</p>
    <h2>{`DMCA policy`}</h2>
    <p>{`This Digital Millennium Copyright Act policy (“Policy”) applies to the fandomnesia.com website (“Website” or “Service”) and any of its related products and services (collectively, “Services”) and outlines how this Website operator (“Operator”, “we”, “us” or “our”) addresses copyright infringement notifications and how you (“you” or “your”) may submit a copyright infringement complaint.`}</p>
    <p>{`Protection of intellectual property is of utmost importance to us and we ask our users and their authorized agents to do the same. It is our policy to expeditiously respond to clear notifications of alleged copyright infringement that comply with the United States Digital Millennium Copyright Act (“DMCA”) of 1998, the text of which can be found at the U.S. Copyright Office website.`}</p>
    <h2>{`What to consider before submitting a copyright complaint`}</h2>
    <p>{`Before submitting a copyright complaint to us, consider whether the use could be considered fair use. Fair use states that brief excerpts of copyrighted material may, under certain circumstances, be quoted verbatim for purposes such as criticism, news reporting, teaching, and research, without the need for permission from or payment to the copyright holder. If you have considered fair use, and you still wish to continue with a copyright complaint, you may want to first reach out to the user in question to see if you can resolve the matter directly with the user.`}</p>
    <p>{`Please note that if you are unsure whether the material you are reporting is in fact infringing, you may wish to contact an attorney before filing a notification with us.`}</p>
    <p>{`We may, at our discretion or as required by law, share a copy of your notification or counter-notification with the account holder engaged in the allegedly infringing activity or for publication. If you are concerned about your information being forwarded, you may wish to use an agent to report infringing material for you.`}</p>
    <h2>{`Notifications of infringement`}</h2>
    <p>{`If you are a copyright owner or an agent thereof, and you believe that any material available on our Services infringes your copyrights, then you may submit a written copyright infringement notification (“Notification”) using the contact details below pursuant to the DMCA. All such Notifications must comply with the DMCA requirements. You may refer to a DMCA takedown notice generator or other similar services to avoid making mistake and ensure compliance of your Notification.`}</p>
    <p>{`Filing a DMCA complaint is the start of a pre-defined legal process. Your complaint will be reviewed for accuracy, validity, and completeness. If your complaint has satisfied these requirements, our response may include the removal or restriction of access to allegedly infringing material as well as a permanent termination of repeat infringers’ accounts. We may also require a court order from a court of competent jurisdiction, as determined by us in our sole discretion, before we take any action. A backup of the terminated account’s data may be requested, however, we may not be able to provide you with one and, as such, you are strongly encouraged to take your own backups.`}</p>
    <p>{`If we remove or restrict access to materials or terminate an account in response to a Notification of alleged infringement, we will make a good faith effort to contact the affected user with information concerning the removal or restriction of access, which may include a full copy of your Notification (including your name, address, phone, and email address), along with instructions for filing a counter-notification.`}</p>
    <p>{`Notwithstanding anything to the contrary contained in any portion of this Policy, the Operator reserves the right to take no action upon receipt of a DMCA copyright infringement notification if it fails to comply with all the requirements of the DMCA for such notifications.`}</p>
    <h2>{`Counter-notifications`}</h2>
    <p>{`A user who receives a copyright infringement Notification may make a counter-Notification pursuant to sections 512(g)(2) and (3) of the US Copyright Act. If you receive a copyright infringement Notification, it means that the material described in the Notification has been removed from our Services or access to the material has been restricted. Please take the time to read through the Notification, which includes information on the Notification we received. To file a counter-notification with us, you must provide a written communication compliant with the DMCA requirements.`}</p>
    <p>{`Please note that if you are not sure whether certain material infringes the copyrights of others or that the material or activity was removed or restricted by mistake or misidentification, you may wish to contact an attorney before filing a counter-notification.`}</p>
    <p>{`Notwithstanding anything to the contrary contained in any portion of this Policy, the Operator reserves the right to take no action upon receipt of a counter-notification. If we receive a counter-notification that complies with the terms of 17 U.S.C. § 512(g), we may forward it to the person who filed the original Notification.`}</p>
    <p>{`The process described in this Policy does not limit our ability to pursue any other remedies we may have to address suspected infringement.`}</p>
    <h2>{`Changes and amendments`}</h2>
    <p>{`We reserve the right to modify this Policy or its terms related to the Website and Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.`}</p>
    <p>{`An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Website and Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes.`}</p>
    <h2>{`Reporting copyright infringement`}</h2>
    <p>{`If you would like to notify us of the infringing material or activity, we encourage you to contact us using the details below:`}</p>
    <p><a parentName="p" {...{
        "href": "mailto:fandomnesia1@gmail.com"
      }}>{`fandomnesia1@gmail.com`}</a></p>
    <p>{`This document was last updated on April 16, 2022`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      